import { StaticImage } from "gatsby-plugin-image"
import * as React from "react"
import Fade from "react-reveal/Fade"
import Roll from "react-reveal/Roll"

const FleetIntro = () => (
    <>
        <div className="container-xxl position-relative">
            <div className="row pt-3 py-md-5">
                <Fade cascade duration={1500} delay={700}>
                    <div className="col-12 mb-3 col-md-6 mb-0 order-md-2 pe-inside z-index-1">
                        <h2 className="pt-md-5">Our Fleet</h2>
                        <p className="our-fleet-text">
                            Every bespoke yacht in our fleet has been through an intensive all encompassing process to deliver a
                            seamless finish to all of our models, ensuring a comfortable, safe and exhilarating experience all day.
                            Large group, couple or family day, we have something for you and guarantee a perfect day sailing around the
                            white isle.
                        </p>
                    </div>
                </Fade>
                <div className="col-12 px-0 col-md-6 order-md-1 ps-inside">
                    <div className="reveal-relative">
                        <Fade right>
                            <StaticImage className="overlay-our-yachts-yacht" src="../images/yachts/maliblue_main.png" alt="water" />
                        </Fade>
                    </div>
                    <StaticImage className="our-yachts-water d-md-none" src="../images/water1.png" alt="water" />
                    {/* <StaticImage className="overlay-frame" src="../images/yacht.png" alt="yacht" /> */}
                    <div className="reveal-relative">
                        <Roll left delay={400}>
                            <StaticImage className="overlay-our-yachts-yacht-frame " src="../images/yacht.png" alt="yacht" />
                        </Roll>
                    </div>
                    <Fade duration={1500} delay={700}>
                        <div className="meet-the-team d-md-none">Meet the Team</div>
                    </Fade>
                </div>
            </div>
        </div>
        <div className="container-xxl pt-0 mt-md-3 position-relative">
            <div className="row">
                <div className="col ps-inside">
                    <Fade duration={1500} delay={700}>
                        <div className="meet-the-team d-none d-md-block ">Meet the team</div>
                    </Fade>
                </div>
            </div>
        </div>
    </>
)

export default FleetIntro

import { Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import * as React from "react"
import fetchQuery from "../utils/fetchQuery"
import formatPrice from "../utils/priceFormatter"
import moment from "moment"
import { BOOKING } from "../utils/constants"
import { useEffect } from "react"

const FleetYachtComponent = ({ y }) => {
    const [yacht, setYacht] = React.useState(y)
    const [isHovered, setIsHovered] = React.useState(false)

    const getLowestYachtPrice = async (vesselId, date) => {
        await fetchQuery({
            method: "GET",
            endPoint: `configuration/Vessel/GetLowestVesselRentalPriceWithinRequestedMonth?vesselId=${vesselId}&date=${date}`,
            successCallback: lowestPrice => {
                setYacht({
                    ...yacht,
                    lowestPrice
                })
            }
        })
    }

    useEffect(() => {
        setYacht(y)
    }, [y])

    React.useEffect(() => {
        if (yacht.netId) {
            const date = moment().format("YYYY-MM-DD")
            getLowestYachtPrice(yacht.netId, date)
        }
    }, [yacht.netId])

    return (
        <Link className="position-relative" style={{ textDecoration: "none" }} to={yacht.link}>
            {yacht.type !== "main" && <div className="multiday-badge">Multi-day</div>}
            <div className="col" onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
                {/* Example of card that is selected, uses classes yacht-selected-card & yacht-selected-card-body */}
                <div className={`card border-white h-100 position-relative ${isHovered ? "yacht-selected-card" : ""}`}>
                    <GatsbyImage
                        onMouseEnter={() => setIsHovered(true)}
                        className="card-img-top"
                        image={getImage(yacht.mainImage)}
                        alt="Yacht Image"
                    />
                    {/* Icon only to show on Booking process selection */}
                    {/* <FontAwesomeIcon className="yacht-info-icon" icon="fas-regular fa-circle-info " size="lg" /> */}
                    <div className={`card-body ${isHovered ? "yacht-selected-card-body" : ""}`}>
                        <div className="row g-0 align-items-center">
                            <div className="col-12 col-md-5">
                                <h4 className="mb-md-0">{yacht.name}</h4>
                            </div>
                            <div className="col-12 col-md-7 d-flex flex-column ">
                                <div className="row g-0">
                                    <div className="col-12 d-flex flex-column align-items-md-end">
                                        <span className="pre-title text-end mb-1">
                                            up to {yacht.capacity} + {yacht.type === "main" ? 1 : 3} people
                                        </span>
                                    </div>
                                    {yacht?.lowestPrice && BOOKING && (
                                        <div className="col-12 d-flex flex-column align-items-md-end">
                                            <span className="price">From €{formatPrice(yacht?.lowestPrice)}</span>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Link>
    )
}

export default FleetYachtComponent

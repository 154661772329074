import * as React from "react"
import { graphql, useStaticQuery } from "gatsby"
import FleetYachtComponent from "./fleetYachtComponent"
import { useState } from "react"
import fetchQuery from "../utils/fetchQuery"
import { useEffect } from "react"

const FleetAllOptions = () => {
    const allYachtsData = useStaticQuery(graphql`
        query AllYachtFleet {
            allYachtsJson(filter: { name: { ne: "default" } }) {
                nodes {
                    id
                    name
                    specification {
                        length
                        beam
                        draft
                        fuel
                        speed
                        berths
                    }
                    model
                    link
                    description {
                        first
                        second
                    }
                    features {
                        first
                        second
                    }
                    mainImage {
                        childImageSharp {
                            gatsbyImageData
                        }
                    }
                    type
                }
            }
        }
    `)
    const yachtsAll = allYachtsData.allYachtsJson.nodes
    const [yachtsData, setYachtsData] = useState(yachtsAll)

    const getYachtServerData = async name => {
        await fetchQuery({
            method: "GET",
            endPoint: `configuration/Vessel/GetVesselByName?vesselName=${name}`,
            successCallback: response => {
                setYachtsData(y => {
                    const copyYachts = [...y]
                    const index = copyYachts.findIndex(y => y.name === name)
                    copyYachts[index] = {
                        ...response,
                        ...copyYachts[index],
                        netId: response.id
                    }

                    return copyYachts
                })
            }
        })
    }

    useEffect(() => {
        yachtsData.forEach(y => {
            getYachtServerData(y.name)
        })
    }, [])

    return (
        <div className=" row row-cols-1 row-cols-md-2 row-cols-xl-3 g-4">
            {yachtsData
                .filter(y => y.isActive)
                .map(y => (
                    <FleetYachtComponent key={y.id} y={y} />
                ))}
        </div>
    )
}

export default FleetAllOptions

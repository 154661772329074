import * as React from "react"
import { Footer } from "../components/footer"
import { NavBar } from "../components/header"
import Layout from "../components/layout"
import Newsletter from "../components/newsletter"
import Seo from "../components/seo"
import FleetIntro from "../components/fleetIntro"
import FleetAll from "../components/fleetAll"
import { StaticImage } from "gatsby-plugin-image"

const OurFleet = () => (
    <Layout>
        <Seo title="Our Fleet" />

        <div className="top-gradient-container">
            <NavBar image />
            <StaticImage className="background-yacht-water d-none d-md-block" src="../images/water1.png" alt="water" />
            <FleetIntro />
            <FleetAll />
        </div>

        <Newsletter />
        <Footer />
    </Layout>
)

export default OurFleet

import * as React from "react"
import FleetAllOptions from "./fleetAllOptions"

const FleetAll = () => (
    <div className="container-xxl position-relative">
        <div className="row pt-4 pb-5">
            <div className="col ps-inside pe-inside">
                <FleetAllOptions />
            </div>
        </div>
    </div>
)

export default FleetAll
